import React from 'react';
import { Col } from 'antd';
import Layout from '../components/Layout';
import CommonPageBanner from '../components/CommonPageBanner';
import OpenhubContentList from '../components/OpenhubContentList';
import TitleAndVerticalBoxes from '../components/TitledVerticalBoxes';
import VerticalBox from '../components/VerticalBox';

export default () => (
  <Layout current="sources" subCurrent="openhub">
    <CommonPageBanner title="Open Hub开放平台" title2="聚合营销生态" imgName="openhub" />
    <OpenhubContentList />
    <TitleAndVerticalBoxes title="三种应用模式" sectionId="openhub" shadowed>
      <Col className="icon-text-col" lg={8} md={24} sm={24} xs={24}>
        <VerticalBox
          iconIndex={55}
          title="品牌自研"
          description="品牌根据自己的业务场景，开发供自己使用的专属应用"
          description2="Convertlab营销云产品许多功能支持灵活扩展，满足品牌多样化需求"
        />
      </Col>
      <Col className="icon-text-col" lg={8} md={24} sm={24} xs={24}>
        <VerticalBox
          iconIndex={69}
          title="ISV应用服务"
          description="面向独立第三方ISV服务商，提供标准化的应用开发流程"
          description2="切入品牌营销痛点，应用可上架服务市场，供品牌选择并以此获利"
        />
      </Col>
      <Col className="icon-text-col" lg={8} md={24} sm={24} xs={24}>
        <VerticalBox
          iconIndex={70}
          title="系统集成"
          description="基于开放平台，服务商为品牌提供定制化集成服务"
          description2="Convertlab营销云提供丰富的API接口，满足多元的集成场景"
        />
      </Col>
    </TitleAndVerticalBoxes>
  </Layout>
);
