import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import Openhub1 from '../images/openhub-1.svg';
import Openhub2 from '../images/openhub-2.svg';
import Openhub3 from '../images/openhub-3.svg';
import Openhub4 from '../images/openhub-4.svg';

export default () => {
  return (
    <div className="content-list-wrapper openhub">
      <div>
        <section className="image-text-section white">
          <Container>
            <ImageText imageSize={10} image={Openhub1} imgWidth={341} imgHeight={328} title="围绕Convertlab营销云建立数字营销生态体系" buttonType="join">
              <ImageTextDescription label="从碎片化的营销场景应用，到一体化闭环营销体系，整个营销生态在不断进化，随着消费者的快速数字化和数字渠道的爆发式增长，企业对数字营销平台和数字营销生态的需求日益增强。Convertlab营销云通过技术融合，与各行各业的优质服务商一起，为企业提供包括销售、电商集成、客服、广告、效率工具、智慧门店、消息推送、游戏应用、内容创意的一体化营销平台能力。" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section blue">
          <Container>
            <ImageText imageSize={10} image={Openhub2} imgWidth={411} imgHeight={326} title="Convertlab营销云开放能力" position="right" buttonType="join">
              <ImageTextDescription label="Convertlab营销云通过元数据驱动设计，支持从界面，到功能，到数据的全面技术融合能力，Convertlab营销云提供超过20个系统扩展点，开发者通过这些丰富的扩展点为企业提供无缝的用户体验和强大的系统功能。" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section white">
          <Container>
            <ImageText imageSize={10} image={Openhub3} imgWidth={413} imgHeight={285} title="应用市场" buttonType="join">
              <ImageTextDescription label="开发者可以基于Convertlab营销云开放平台，根据洞察到的企业营销痛点，开发并发布营销应用。企业主可以从海量营销应用中选择合适的应用进行安装并使用，一键安装，立刻使用。" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section blue big-image">
          <Container>
            {/* 这张图片纵向阴影太高，所以imgHeight从真实的426改为380 */}
            <ImageText imageSize={10} image={Openhub4} imgWidth={411} imgHeight={380} title="开发者服务" position="right" buttonType="join">
              <ImageTextDescription label="我们将提供一对一的技术咨询，专业的培训指导，丰富的接口文档，稳定的系统服务，以及数据独立的免费测试账号。未来我们也将构建完整的开发者互动社区以完善服务。" />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
